import React from "react";

import styles from "./MainLogo.module.css"
import mainLogo from "../../infrastructure/res/commonIcons/mainLogo.svg";

const MainLogo = () => {
    return (
      <img className={styles.mainLogo} src={mainLogo}  alt="mainLogo" />
    );
}

export default React.memo(MainLogo);
