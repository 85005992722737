import {createAction} from "@reduxjs/toolkit";
import {ApiErrorDescriptor} from "../../infrastructure/api/ApiErrorDescriptor";
import {authPing} from "../../infrastructure/api/methods/AuthMethods";
import {createAuthInfo} from "../../infrastructure/api/utils/createAuthInfoString";
import {onError} from "../utils/onError";

enum AuthActionTypes {
    AUTH_LOGIN_START = "AUTH_LOGIN_START",
    AUTH_SUCCESS = "AUTH_SUCCESS",
    AUTH_ERROR = "AUTH_ERROR",
    AUTH_LOGOUT = "AUTH_LOGOUT"
}

export const authLoginStart = createAction(
    AuthActionTypes.AUTH_LOGIN_START,
    (newAbortController: AbortController) => ({
        payload: newAbortController
    })
);

export const authLogout = createAction(AuthActionTypes.AUTH_LOGOUT);

export const authSuccess = createAction(
    AuthActionTypes.AUTH_SUCCESS,
    (authInfo: string) => ({
        payload: authInfo
    })
);

export const authError = createAction(
    AuthActionTypes.AUTH_ERROR,
    (apiErrorInfo: ApiErrorDescriptor) => ({
        payload: apiErrorInfo
    })
);

export const tryLogin = (login: string, password: string) => {
    return async (dispatch: any) => {
        let abortController = new AbortController();
        dispatch(authLoginStart(abortController));
        try {
            const authInfo = createAuthInfo(login, password);

            await authPing(authInfo, abortController.signal);

            dispatch(authSuccess(authInfo));
        } catch (err) {
            onError(err, dispatch)
        }
    }
}

