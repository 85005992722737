import {CacheShelfLife} from "./commonTypes";

export const constants = {
    CACHE_SHELF_LIFE: { hours: 0, minutes: 3, seconds: 0 } as CacheShelfLife,

    routing: {
        TARGET_FORM_UPDATE_ROUTE: '/targetForm/update',
        TARGET_FORM_CREATE_ROUTE: '/targetForm/create'
    },

    AUTH_STORAGE_KEY: 'mason-auth-key'
};
