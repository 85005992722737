import {authorizedFetch} from "../utils/authorizedFetch";
import {apiConfig} from "../apiConfig";
import {responseToJson} from "../utils/responseToJson";
import {TargetsDto} from "../dto/TargetsDto";
import {TargetToUpdateDto} from "../dto/TargetToUpdateDto";
import {ApiErrorReason} from "../ApiErrorDescriptor";
import {AbortSignal} from "node-fetch/externals";

const TARGETS_REASON: ApiErrorReason = 'targets';

export const getTargets = (signal: AbortSignal) => {
    return authorizedFetch(`${apiConfig.BASE_URL + apiConfig.TARGET_ROUTE}`, signal, {method: 'GET'})
        .then(response => responseToJson(response, TARGETS_REASON))
        .then(data => data as TargetsDto);
}


export const deleteTargetById = (id: number, signal: AbortSignal) =>
    authorizedFetch(`${apiConfig.BASE_URL + apiConfig.TARGET_ROUTE + `/${id}`}`, signal, {method: 'DELETE'})
        .then(response => responseToJson(response, TARGETS_REASON));

export const putTargetFromDto = (dto: TargetToUpdateDto, signal: AbortSignal) =>
    authorizedFetch(`${apiConfig.BASE_URL + apiConfig.TARGET_ROUTE}`, signal,
        {
            method: 'PUT',
            body: JSON.stringify(dto),
        })
        .then(response => responseToJson(response, TARGETS_REASON));
