import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import styles from "./TargetPlotsPage.module.css"
import BackWithLogo from "../../components/CommonHeading/CommonHeading";
import {useAppSelector} from "../../redux/hooks";
import ErrorPage from "../ErrorPage/ErrorPage";
import {TargetPlots} from "../../components/TargetPlots/TargetPlots";
import {developmentLog} from "../../infrastructure/utils/developmentLog";


export const TargetPlotsPage: React.FC = () => {
    const params = useParams<{targetId: string}>();

    const [targetId, setTargetId] = useState<number>(-1);

    useEffect(() => {
        developmentLog(`targetPlotsParams: ${JSON.stringify(params)}`);
        setTargetId(parseInt(params.targetId || '-1'));
    }, [params]);

    const currentTarget = useAppSelector(
        state => state.targetsReducer.targetsData.data.filter(data => data.id === targetId));

    return (
        <>
            {
                (currentTarget.length > 0) ?
                    (
                        <div className={styles.targetPlotsPage}>
                            <BackWithLogo redirectPath="/targets"/>
                            <TargetPlots id={targetId} targetInfo={currentTarget[0].targetInfo}/>
                        </div>
                    ) :
                    <ErrorPage errorCode="404" message="Страницы не существует" />
            }
        </>
    );
}
