import {ApiErrorDescriptor} from "../infrastructure/api/ApiErrorDescriptor";
import {AccountType} from "../infrastructure/commonTypes";

export enum ApiRequestStatus {
    PENDING,
    SUCCESS,
    ERROR
}

export type ApiRequestCondition = {
    status: ApiRequestStatus,
    warningMessage?: string,
    errorInfo?: ApiErrorDescriptor
}

export interface ApiSynchronizedData<TData> {
    data: TData,
    abortController?: AbortController
    apiRequestCondition: ApiRequestCondition
}

export type ChartTargetInfo = {
    id: number,
    accountType: AccountType
}
