import ApiError from "../exceptions/ApiError";
import {ApiErrorReason} from "../ApiErrorDescriptor";
import {ErrorDto} from "../dto/ErrorDto";
import {developmentLog} from "../../utils/developmentLog";
import {Response} from "node-fetch";

export const responseToJson = async (response: Response, reasonIfBad: ApiErrorReason) => {
    developmentLog(`got response: ${response.status}`);
    if (!response.ok) {
        developmentLog(`response is not ok`);
        // throw new ApiError({code: 503, message: ApiErrorMessages.NO_CONNECTION, reason: 'common'});
    }

    if (response.status === 401 && reasonIfBad !== 'auth') {
        throw new ApiError({code: response.status, message: 'The user is unauthorized', reason: 'common'});
    }

    const contentType = response.headers.get("content-type");
    const hasJsonContent = contentType && contentType.indexOf("application/json") !== -1;

    if (response.status === 200) {
        return hasJsonContent ? response.json() : null;
    }

    let message: string | undefined = undefined;

    if (hasJsonContent) {
        developmentLog(`got error message: parsing...`);
        message = ((await response.json()) as ErrorDto).message;

        developmentLog(`got error message: ${message}`);
    }
    throw new ApiError({code: response.status, message: message, reason: reasonIfBad});
}

