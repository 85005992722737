import React from "react";
import Button, {ButtonProps} from "./Button";
import styles from './Button.module.css'

class LargeButton extends React.Component<ButtonProps> {
    render() {
        return <Button additionalClassName={styles.large} {...this.props} children={this.props.children} />
    }
}

export default React.memo(LargeButton)
