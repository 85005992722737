import {createAction} from "@reduxjs/toolkit";
import {ApiErrorDescriptor} from "../../infrastructure/api/ApiErrorDescriptor";
import {TargetInfoWithId} from "../../infrastructure/commonTypes";
import {getTargetsFromDto, TargetsDto} from "../../infrastructure/api/dto/TargetsDto";
import {onError} from "../utils/onError";
import {deleteTargetById, getTargets, putTargetFromDto} from "../../infrastructure/api/methods/TargetsMethods";
import {getTargetToUpdateDto} from "../../infrastructure/api/dto/TargetToUpdateDto";
import {AbortSignal} from "node-fetch/externals";

enum TargetsActionTypes {
    TARGETS_LOADING_START = "TARGETS_LOADING_START",
    TARGETS_LOADING_SUCCESS = "TARGETS_LOADING_SUCCESS",
    TARGETS_LOADING_ERROR = "TARGETS_LOADING_ERROR"
}

export const targetsLoadingStart = createAction(
    TargetsActionTypes.TARGETS_LOADING_START,
    (newAbortController: AbortController) => ({
        payload: newAbortController
    })
);

export const targetsLoadingSuccess = createAction(
    TargetsActionTypes.TARGETS_LOADING_SUCCESS,
    (targets: TargetInfoWithId[]) => ({
        payload: targets
    })
);

export const targetsLoadingError = createAction(
    TargetsActionTypes.TARGETS_LOADING_ERROR,
    (apiErrorInfo: ApiErrorDescriptor) => ({
        payload: apiErrorInfo
    })
);

const loadTargetsFromServer = async (signal: AbortSignal) => {
    const targetsDto: TargetsDto = await getTargets(signal);
    return getTargetsFromDto(targetsDto);
}

export const reloadTargets = () => {
    return async (dispatch: any) => {
        let abortController = new AbortController();
        dispatch(targetsLoadingStart(abortController));

        try {
            const targets = await loadTargetsFromServer(abortController.signal);
            dispatch(targetsLoadingSuccess(targets));

            return targets;
        } catch (err) {
            return onError(err, dispatch);
        }
    }
}

export const deleteTarget = (id: number) => {
    return async (dispatch: any) => {
        let abortController = new AbortController();
        dispatch(targetsLoadingStart(abortController));

        try {
            await deleteTargetById(id, abortController.signal);

            const targets = await loadTargetsFromServer(abortController.signal);
            dispatch(targetsLoadingSuccess(targets));

            return targets;
        } catch (err) {
            return onError(err, dispatch);
        }
    }
}


export const updateTarget = (targetToUpdate: TargetInfoWithId) => {
    return async (dispatch: any) => {
        let abortController = new AbortController();
        dispatch(targetsLoadingStart(abortController));

        try {
            const targetToUpdateDto = getTargetToUpdateDto(targetToUpdate);
            await putTargetFromDto(targetToUpdateDto, abortController.signal);

            const targets = await loadTargetsFromServer(abortController.signal);
            dispatch(targetsLoadingSuccess(targets));

            return targets;
        } catch (err) {
            return onError(err, dispatch);
        }
    }
}
