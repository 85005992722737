import moment from 'moment';
import React from "react";

import {DatePicker} from "antd";
import { RangeValue } from 'rc-picker/lib/interface.d';

import styles from "./DateTimeRangePicker.module.css";

const {RangePicker} = DatePicker;

type DateTimeRangePickerProps = {
    initialValue: [moment.Moment, moment.Moment],
    onChange: (newRange: [moment.Moment, moment.Moment]) => void
}


export const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = (props) => {
    const onRangePickerChange = (dates: RangeValue<moment.Moment>) => {
        props.onChange(dates as [moment.Moment, moment.Moment]);
    }
    return (
        <RangePicker
            className={styles.dateTimePicker}
            allowClear={false}
            defaultValue={props.initialValue}
            format="DD-MM-YYYY HH:mm"
            showTime={true}
            onChange={onRangePickerChange}
        />
    );
};
