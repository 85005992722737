import {authorizedFetch} from "../utils/authorizedFetch";
import {apiConfig} from "../apiConfig";
import {responseToJson} from "../utils/responseToJson";
import {ApiErrorReason} from "../ApiErrorDescriptor";
import {AbortSignal} from "node-fetch/externals";

const AUTH_REASON: ApiErrorReason = 'auth';

export const authPing = (authInfo: string, signal: AbortSignal) =>
    authorizedFetch(`${apiConfig.BASE_URL + apiConfig.AUTH_ROUTE}`, signal,
        {
            method: 'GET',
            authInfo
        })
        .then(response => responseToJson(response, AUTH_REASON));
