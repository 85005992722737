import React from "react";

import styles from "./AccountsInfoForm.module.css";
import {accountTypesDescription} from "../../infrastructure/accountTypesDescription";
import cn from "classnames";
import minusCircle from "../../infrastructure/res/commonIcons/deleteIcon.svg";

type AccountTypeInfoInputProps = {
    value: string,
    code: string,
    onChange: (newValue: string) => void
    onDelete: () => void
}

const AccountTypeInfoInput: React.FC<AccountTypeInfoInputProps> = (props) => {
    let accountTypeDescription = accountTypesDescription
        .filter(atd => atd.name === props.code)[0];

    let input = (<input className={styles.accountInfoInput} placeholder={accountTypeDescription.placeholder}
                        required={true}
                        pattern={accountTypeDescription.regex.source} title={accountTypeDescription.description}
                        maxLength={accountTypeDescription.maxLength}
                        value={props.value}
                        onChange={(event) => props.onChange(event.target.value)}/>);

    let icon = (
        <img className={styles.icon} src={accountTypeDescription.logo} alt={`${accountTypeDescription.name} logo`}/>);

    let deleteIcon = (
        <img className={cn(styles.deleteIcon, styles.clickable)} src={minusCircle} alt="minusCircle"
             onClick={() => props.onDelete()}
        />);

    return (
        <div className={styles.singleAccountInfoContainer}>
            {deleteIcon}
            {icon}
            {input}
        </div>
    );
}

export default React.memo(AccountTypeInfoInput);
