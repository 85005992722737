import {ApiRequestStatus, ApiSynchronizedData} from "../types";
import {createReducer} from "@reduxjs/toolkit";
import {authLoginStart, authSuccess, authError, authLogout} from "./actions";
import {deleteAuthInfoFromStorage, setAuthInfoToStorage} from "../../infrastructure/utils/AuthStorageUtils";

const initState: { authData: ApiSynchronizedData<string | undefined> } = {
    authData: {
        data: undefined,
        apiRequestCondition: {
            status: ApiRequestStatus.SUCCESS,
        }
    }
}

export const authReducer = createReducer(initState, (builder) => {
    builder
        .addCase(authLoginStart, (state, action) => {
            if (state.authData.abortController) {
                state.authData.abortController.abort();
            }

            state.authData.abortController = action.payload;

            state.authData.apiRequestCondition.status = ApiRequestStatus.PENDING;
        })
        .addCase(authError, (state, action) => {
            state.authData.abortController = undefined;

            state.authData.apiRequestCondition = {
                status: ApiRequestStatus.ERROR,
                errorInfo: action.payload
            };
        })
        .addCase(authLogout, (state) => {
            state.authData.abortController = undefined;
            deleteAuthInfoFromStorage();

        })
        .addCase(authSuccess, (state, action) => {
            setAuthInfoToStorage(action.payload);
            state.authData.abortController = undefined;
            state.authData.apiRequestCondition = {
                status: ApiRequestStatus.SUCCESS
            }
        });
});
