import {ApiRequestStatus, ApiSynchronizedData} from "../types";
import {TargetInfoWithId} from "../../infrastructure/commonTypes";
import {createReducer} from "@reduxjs/toolkit";
import {targetsLoadingError, targetsLoadingStart, targetsLoadingSuccess} from "./actions";
import {authLogout} from "../auth/actions";

const initState: { targetsData: ApiSynchronizedData<TargetInfoWithId[]> } = {
    targetsData: {
        data: [],
        apiRequestCondition: {
            status: ApiRequestStatus.SUCCESS,
        }
    }
}

export const targetsReducer = createReducer(initState, (builder) => {
    builder
        .addCase(targetsLoadingStart, (state, action) => {
            if (state.targetsData.abortController) {
                // старый абортится и далее заменяется на новый
                state.targetsData.abortController.abort();
            }

            state.targetsData.abortController = action.payload;

            state.targetsData.apiRequestCondition = {
                status: ApiRequestStatus.PENDING
            };
        })
        .addCase(targetsLoadingError, (state, action) => {
            state.targetsData.abortController = undefined;

            state.targetsData.apiRequestCondition = {
                status: ApiRequestStatus.ERROR,
                errorInfo: action.payload
            };
        })
        .addCase(targetsLoadingSuccess, (state, action) => {
            state.targetsData = {
                data: action.payload,
                apiRequestCondition: {
                    status: ApiRequestStatus.SUCCESS
                }
            }
        })
        .addCase(authLogout, (state) => {
            if (state.targetsData.abortController) {
                state.targetsData.abortController.abort();
            }
            state.targetsData = initState.targetsData;
        });
});
