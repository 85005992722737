import React from "react";
import styles from './Button.module.css'
import {useHistory} from "react-router-dom";
import back from "./res/back.svg";

const BackButton: React.FC<{redirectPath?: string}> = ({redirectPath}) => {
    const history = useHistory();

    return (
        <img className={styles.clickable}
             onClick={redirectPath ? () => history.push(redirectPath) : history.goBack} src={back} alt="backIcon"/>
    );
}

export default React.memo(BackButton);
