import React from "react";
import {checkAuth} from "../infrastructure/utils/AuthStorageUtils";
import { Redirect } from "react-router-dom";

// @ts-ignore
export const AuthHandler = ({children}) => {
    return checkAuth() ? children : <Redirect to="/login"/>;
};

export default React.memo(AuthHandler);
