import {AccountType} from "../../infrastructure/commonTypes";
import React from "react";

import styles from "./TargetPlots.module.css";
import cn from "classnames";
import {accountTypesDescription} from "../../infrastructure/accountTypesDescription";

type AccountTypePickerProps = {
    availableTypes: AccountType[],
    notifyWithPickedType: (at: AccountType) => void,
    pickedType?: AccountType
}

const AccountTypePicker: React.FC<AccountTypePickerProps> = ({availableTypes, notifyWithPickedType, pickedType}) => {

    const renderAccountType = (at: AccountType, ind: number) => {
        let isPicked = (at === pickedType);
        let imgSrc = getAccountTypeIconSrc(at, isPicked);
        return (
            <div key={ind} className={getContainerClassNames(isPicked)}
                 onClick={isPicked ? undefined : () => {
                     notifyWithPickedType(at)
                 }}>

                {imgSrc ?
                    <img className={styles.icon} src={imgSrc} alt={`${at}Logo`}/>
                    : null}
            </div>
        );
    }

    return (
        <div className={styles.accountTypesContainer}>
            {availableTypes.map(renderAccountType)}
        </div>
    );
}

const getAccountTypeIconSrc = (accountType: AccountType, isPicked: boolean) => {
    let suitable = accountTypesDescription
        .filter(atd => atd.name === accountType)
        .map(atd => (isPicked ? atd.previewLogo : atd.logo));
    return suitable.length > 0 ? suitable[0] : null;
}

const getContainerClassNames = (isPicked: boolean) => {
    return isPicked ?
        cn(styles.iconContainer, styles.blackBackground)
        : cn(styles.iconContainer, styles.whiteBackground);
}

export default React.memo(AccountTypePicker);
