import {constants} from "../constants";

export const checkAuth = (): boolean => {
    const resultItem = getAuthInfoFromStorage();
    return !!resultItem;
}

export const getAuthInfoFromStorage = (): string => {
    return sessionStorage.getItem(constants.AUTH_STORAGE_KEY) ?? '';
}

export const deleteAuthInfoFromStorage = () => {
    sessionStorage.removeItem(constants.AUTH_STORAGE_KEY);
}

export const setAuthInfoToStorage = (authInfo: string) => {
    sessionStorage.setItem(constants.AUTH_STORAGE_KEY, authInfo);
}
