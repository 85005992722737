import React from "react";
import {useAppDispatch} from "../../redux/hooks";

import styles from "./CommonHeading.module.css"
import cn from "classnames";
import logoutIcon from "./res/logoutIcon.svg";
import {authLogout} from "../../redux/auth/actions";

import{useHistory} from "react-router-dom";

export const LogoutFeature: React.FC = React.memo(() => {

    const dispatch = useAppDispatch();
    const history = useHistory();

    const logoutAction = () => {
        dispatch(authLogout());
        history.push("/login");
    }

    return (
        <img className={cn(styles.logoutIcon, styles.clickable)}
             src={logoutIcon} alt="logout icon" onClick={logoutAction}/>
    );
});
