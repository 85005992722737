import React from "react";
import BackButton from "../Button/BackButton";
import styles from './CommonHeading.module.css'
import MainLogo from "../MainLogo/MainLogo";
import {LogoutFeature} from "./LogoutFeature";

import padlockIcon from "./res/padlockIcon.svg"

const CommonHeading: React.FC<{redirectPath?: string, cantLogout?: boolean}> = ({redirectPath, cantLogout}) => {
    return (
        <div className={styles.backWithLogo}>
            <BackButton redirectPath={redirectPath}/>
            <MainLogo />
            {cantLogout ?
                <img className={styles.icon} src={padlockIcon} alt="padlock icon" />
                : <LogoutFeature />}
        </div>
    );
}

export default React.memo(CommonHeading);
