import {createAction} from "@reduxjs/toolkit";
import {ApiErrorDescriptor} from "../../infrastructure/api/ApiErrorDescriptor";
import {ChartTargetInfo} from "../types";
import {ChartIntervalsDto} from "../../infrastructure/api/dto/ChartIntervalsDto";
import {OnlineQueryDto} from "../../infrastructure/api/dto/OnlineQueryDto";
import {onError} from "../utils/onError";
import {getPlotIntervals} from "../../infrastructure/api/methods/ChartsMethods";
import ApiError from "../../infrastructure/api/exceptions/ApiError";

enum ChartsActionTypes {
    CHART_LOADING_START = "CHART_LOADING_START",
    CHART_LOADING_SUCCESS = "CHART_LOADING_SUCCESS",
    CHART_LOADING_ERROR = "CHART_LOADING_ERROR",
}

export const chartLoadingStart = createAction(
    ChartsActionTypes.CHART_LOADING_START,
    (chartTargetInfo: ChartTargetInfo, newAbortController: AbortController) => ({
        payload: {chartTargetInfo, newAbortController}
    })
);

export const chartLoadingSuccess = createAction(
    ChartsActionTypes.CHART_LOADING_SUCCESS,
    (chartTargetInfo: ChartTargetInfo, result: ChartIntervalsDto) => ({
        payload: {chartTargetInfo, result}
    })
);

export const chartLoadingError = createAction(
    ChartsActionTypes.CHART_LOADING_ERROR,
    (apiErrorInfo: ApiErrorDescriptor) => ({
        payload: apiErrorInfo
    })
);

export const loadChart = (intervalQuery: OnlineQueryDto) => {
    return async (dispatch: any) => {
        let abortController = new AbortController();
        dispatch(chartLoadingStart({
            accountType: intervalQuery.accountType,
            id: intervalQuery.targetId
        }, abortController));

        try {
            let result = await getPlotIntervals(intervalQuery, abortController.signal);

            // TODO: забыл сказать на бэке 400 возвращать вместо 200, если интервал неправильный
            if (result['message']) {
                throw new ApiError({code: 400, message: result['message'], reason: 'charts'} as ApiErrorDescriptor);
            }

            dispatch(chartLoadingSuccess({
                accountType: intervalQuery.accountType,
                id: intervalQuery.targetId
            }, result as ChartIntervalsDto));
        } catch (err) {
            onError(err, dispatch);
        }
    }
}
