import moment from "moment";
import {apiConfig} from "../api/apiConfig";


const localOffsetMs = (new Date()).getTimezoneOffset() * 60000;
const serverOffsetMs = -apiConfig.SERVER_UTC * 60 * 60000;

export const toLocalDate = (parsedIsoDate: Date) => {
    // developmentLog(`date from server ${JSON.stringify(parsedIsoDate)}`);
    // developmentLog(`local date ${JSON.stringify(localDate)}`);
    return moment(parsedIsoDate)
        .add(serverOffsetMs, 'ms')
        .subtract(localOffsetMs, 'ms').toDate();
}

export const toServerISODate = (date: Date) => {
    // developmentLog(`${localOffsetMs}`);
    // developmentLog(`${serverOffsetMs}`);
    return moment(date)
        .subtract(serverOffsetMs, 'ms')
        .toDate();
}