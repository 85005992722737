import React from 'react';
import styles from './Button.module.css'
import cn from 'classnames';

export type ButtonProps = {
    type?: 'button' | 'reset' | 'submit',
    children: string,
    additionalClassName?: string,
    onClick?:  React.MouseEventHandler<HTMLButtonElement>
}

class Button extends React.Component<ButtonProps> {
    render() {
        return <button className={cn(styles.button, styles.clickable, this.props.additionalClassName)} type={this.props.type} onClick={this.props.onClick}>
            {this.props.children}
        </button>
    }
}

export default React.memo(Button);
