import React, {useEffect, useState} from "react";

import {AccountType, TargetInfo} from "../../infrastructure/commonTypes";
import Description from "../Description/Description";
import AccountTypePicker from "./AccountTypePicker";
import {ResponsiveChart} from "../ResponsiveChart/ResponsiveChart";

type TargetWithIdRequired = {
    id: number,
    targetInfo: TargetInfo
}


export const TargetPlots: React.FC<TargetWithIdRequired> = (props) => {
    const [pickedType, setPickedType] = useState<AccountType>();

    useEffect(() => {
        setPickedType(props.targetInfo.accountsInfo.length === 0 ?
            undefined
            : props.targetInfo.accountsInfo[0].accountName as AccountType)
    }, [props]);

    const renderAccountTypePicker = () => {
        if (props.targetInfo.accountsInfo.length === 0)
            return <Description type="warning" message={"Для цели не добавлены аккаунты"}/>;

        return <AccountTypePicker availableTypes={props.targetInfo.accountsInfo.map(ai => ai.accountName as AccountType)}
                                  notifyWithPickedType={(at) => {setPickedType(at)}}
                                  pickedType={pickedType}/>
    };

    const renderResponsiveChart = () => {
        if (!pickedType)
            return null;

        if (pickedType !== 'vk')
            return <Description type="warning" message={"Пока что поддерживается только vk"} />

        return <ResponsiveChart accountType={pickedType} id={props.id} />;
    };

    return (
        <>
            <Description message={`ГРАФИК АКТИВНОСТИ ЦЕЛИ '${props.targetInfo.mainNickname}'`}/>
            {renderAccountTypePicker()}
            {renderResponsiveChart()}
        </>
    );
}
