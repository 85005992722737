import React, {useEffect} from 'react';
import 'antd/dist/antd.css';
import './App.css';
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import ErrorHandler from "../components/ErrorHandler";
import {TargetsPage} from "../pages/TargetsPage/TargetsPage";
import LandingPage from "../pages/LandingPage/LandingPage";
import {AccountsInfoForm} from "../components/AccountsInfoForm/AccountsInfoForm";
import {TargetPlotsPage} from "../pages/TargetPlotsPage/TargetPlotsPage";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {constants} from "../infrastructure/constants";
import {reloadTargets} from "../redux/targets/actions";
import {AuthHandler} from "../components/AuthHandler";
import AuthPage from "../pages/AuthPage/AuthPage";
import {checkAuth} from "../infrastructure/utils/AuthStorageUtils";

function App() {
    const targets = useAppSelector(state => state.targetsReducer.targetsData.data);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (checkAuth()) {
            dispatch(reloadTargets());
        }
    }, [dispatch]);

    return (
        <div className="App">
            <BrowserRouter>
                <ErrorHandler>
                    <Switch>
                        <Route exact path="/login"
                               component={AuthPage}
                        />
                        <Route exact path="/">
                            <Redirect to='/home'/>
                        </Route>
                        <Route
                            exact path="/home"
                            render={() => <LandingPage/>}
                        />

                        <AuthHandler>
                            <Switch>
                                <Route
                                    exact path="/targets"
                                    render={() => <TargetsPage/>}
                                />
                                <Route
                                    exact path={constants.routing.TARGET_FORM_CREATE_ROUTE}
                                    render={() => <AccountsInfoForm/>}
                                />
                                {targets ? targets.map((ti, index) =>
                                    <Route key={index} exact path={`${constants.routing.TARGET_FORM_UPDATE_ROUTE}/${ti.id}`}
                                           render={() => <AccountsInfoForm
                                               id={ti.id}
                                               targetInfo={ti.targetInfo}/>}
                                    />) : null}
                                <Route
                                    exact path={`/targetPlots/:targetId(\\d+)`}
                                    component={TargetPlotsPage}
                                />
                                <Route render={() => <ErrorPage errorCode="404" message="Страницы не существует"/>}/>
                            </Switch>
                        </AuthHandler>
                    </Switch>
                </ErrorHandler>
            </BrowserRouter>
        </div>
    );
}

export default App;
