import React, {useEffect, useState} from "react";

import styles from "./ResponsiveChart.module.css";
import cn from "classnames";
import {developmentLog} from "../../infrastructure/utils/developmentLog";
import Button from "../Button/Button";
import Description from "../Description/Description";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ApiRequestStatus, ChartTargetInfo} from "../../redux/types";
import {loadChart} from "../../redux/charts/actions";
import {getOnlineQueryDto} from "../../infrastructure/api/dto/OnlineQueryDto";
import {setError} from "../../redux/error/actions";
import {AppErrorDescriptor} from "../../infrastructure/commonTypes";
import Loader from "../Loader/Loader";

import moment from 'moment';
import {DateTimeRangePicker} from "../DateTimeRangePicker/DateTimeRangePicker";
import {ChartView} from "./ChartView";

type ResponsiveChartProps = ChartTargetInfo

export const ResponsiveChart: React.FC<ResponsiveChartProps> = ({id, accountType}) => {
    const [dateTimeRange, setDateTimeRange] =
        useState<[moment.Moment, moment.Moment]>([moment(new Date()).subtract(4, "hours"), moment(new Date())]);

    const [lastSentDateTimeRange, setLastSentDateTimeRange] =
        useState<[moment.Moment, moment.Moment]>();

    const {chartTargetInfo, chartData} = useAppSelector(state => state.chartsReducer);
    const dispatch = useAppDispatch();

    const [isStateActual, setIsStateActual] = useState<boolean>(false);
    const [badRequestMsg, setBadRequestMsg] = useState<string | undefined>(undefined);

    useEffect(() => {
        developmentLog('value changed' + dateTimeRange[0].toISOString(true) + ' ' + dateTimeRange[1].toISOString(true));
    }, [dateTimeRange]);

    useEffect(() => {
        if (isStateActual) {
            if (chartData.apiRequestCondition.status === ApiRequestStatus.SUCCESS) {
                setBadRequestMsg(undefined);
                setIsStateActual(false);
            }
            if (chartData.apiRequestCondition.status === ApiRequestStatus.ERROR) {
                if (chartData.apiRequestCondition.errorInfo?.code === 400) {
                    setBadRequestMsg(`Некорректные данные: ${chartData.apiRequestCondition.errorInfo?.message}`);
                } else {
                    dispatch(setError({
                        errorType: 'API',
                        code: chartData.apiRequestCondition.errorInfo?.code,
                        message: chartData.apiRequestCondition.errorInfo?.message
                    } as AppErrorDescriptor));
                }
            }
        }
    }, [chartData, dispatch, isStateActual]);

    const onFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        dispatch(loadChart(getOnlineQueryDto({id, accountType}, dateTimeRange)));
        setIsStateActual(true);
        setLastSentDateTimeRange({...dateTimeRange});
    }

    const renderQueryResult = () => {
        if (chartTargetInfo?.id === id && chartTargetInfo?.accountType === accountType) {
            if (badRequestMsg) {
                return <Description message={badRequestMsg} type={"warning"} />
            }
            if (chartData.apiRequestCondition.status === ApiRequestStatus.PENDING) {
                return <Loader size='small'/>
            }
            if (chartData.apiRequestCondition.status === ApiRequestStatus.SUCCESS && chartData.data) {
                let segmentsCopy = JSON.parse(JSON.stringify(chartData.data));
                if (segmentsCopy) {
                    return <ChartView
                        segments={segmentsCopy}
                        minimum={lastSentDateTimeRange ? lastSentDateTimeRange[0].toDate() : undefined}
                        maximum={lastSentDateTimeRange ? lastSentDateTimeRange[1].toDate() : undefined}
                    />
                }
            }
        }
        return null;
    }

    return (
        <div className={cn(styles.mainContainer)}>
            <Description type="smallInfo" message="ВЫСТАВЬТЕ ИНТЕРВАЛ"/>
            <form className={styles.formContainer} onSubmit={onFormSubmit}>
                <DateTimeRangePicker initialValue={dateTimeRange} onChange={setDateTimeRange} />
                <Button additionalClassName={styles.sendFormButton} type="submit">ЗАГРУЗИТЬ ГРАФИК</Button>
            </form>
            {renderQueryResult()}
        </div>
    );
}
