import vkLogo from "./res/commonIcons/accountTypes/vkLogo.svg";
import vkLogoWhite from "./res/commonIcons/accountTypes/preview/vkLogoWhite.svg";
import tgLogo from "./res/commonIcons/accountTypes/tgLogo.svg"
import tgLogoWhite from "./res/commonIcons/accountTypes/preview/tgLogoWhite.svg"
// import waLogo from "./res/commonIcons/accountTypes/waLogo.svg"

export type AccountTypeDescription = {
    name: string,
    regex: RegExp,
    maxLength: number,
    description: string,
    placeholder: string,
    logo: string,
    previewLogo: string
}

export const accountTypesDescription: AccountTypeDescription[] = [
    {
        name: 'vk',
        regex: new RegExp('[a-zA-z0-9]+'),
        maxLength: 60,
        placeholder: 'nickname',
        description: 'Строка из лат. букв и цифр',
        logo: vkLogo,
        previewLogo: vkLogoWhite
    },
    {
        name: 'tg',
        regex: new RegExp('[a-zA-z0-9]+'),
        maxLength: 60,
        placeholder: 'nickname',
        description: 'Строка из лат. букв и цифр',
        logo: tgLogo,
        previewLogo: tgLogoWhite
    },
    // {
    //     name: 'wa',
    //     regex: new RegExp('@[a-zA-z0-9]+'),
    //     placeholder: '@nickname',
    //     description: 'Строка из лат. букв и цифр, начинающаяся с @',
    //     logo: waLogo
    // },
];

export const AVAILABLE_ACCOUNT_TYPES = accountTypesDescription.map(atd => atd.name as ('vk' | 'tg' | 'wa'));
