import {ApiErrorDescriptor} from "../ApiErrorDescriptor";

export default class ApiError extends Error {
    readonly descriptor: ApiErrorDescriptor;

    constructor(descriptor: ApiErrorDescriptor) {
        super(descriptor.message);
        this.name = 'ApiError';
        this.descriptor = descriptor;
    }
}
