export const apiConfig = {
    AUTH_LOGIN: 'admin',
    AUTH_PASSWORD: 'sasamba',

    BASE_URL: 'https://social-monitor-2021.herokuapp.com/api',
    TARGET_ROUTE: '/targets',
    CHARTS_ROUTE: '/charts',
    AUTH_ROUTE: '/auth/ping',

    SERVER_UTC: 3
};
