import {SegmentDto} from "../../infrastructure/api/dto/ChartIntervalsDto";
import React, {useEffect, useState} from "react";
import moment from "moment";

import CanvasJSReact from "../../infrastructure/lib/canvasjs-3.2.16/canvasjs.react";
import {developmentLog} from "../../infrastructure/utils/developmentLog";
import {toLocalDate} from "../../infrastructure/utils/DateUtils";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

type ChartViewProps = {
    segments: SegmentDto[],
    minimum?: Date,
    maximum?: Date
};

type Point = {
    label: string,
    x: Date,
    y: number
}

export const formatWithMoment = (date: Date): string => {
    return moment(date).format("DD-MM-YYYY HH:mm:ss");
}

const createPoint = (date: Date, online: number): Point => {
    return ({label: formatWithMoment(date), x: date, y: online});
}

const createDataPoints = (segments: SegmentDto[]) => {
    developmentLog(`gotSegments ${JSON.stringify(segments)}`);
    return segments.flatMap(segment => {
            const x1Val = toLocalDate(new Date(segment.begin));
            const x2Val = toLocalDate(new Date(segment.end));
            return [
                createPoint(x1Val, 1),
                createPoint(x2Val, 0)
                // ({label: formatWithMoment(x1Val), x: x1Val, y: 1}),
                // ({label: formatWithMoment(x2Val), x: x2Val, y: 0})
            ]
        }
    );
}

const createChartData = (segments: SegmentDto[]) => {
    let dataPoints = createDataPoints(segments);
    developmentLog(`convertedSegments ${JSON.stringify(dataPoints)}`);
    return [
        {
            type: "stepArea",
            color: "black",
            dataPoints: dataPoints,
        }
    ]
}

const xLabelFormatter = (e: any) => formatWithMoment(e.value);

const createChartOptions = (segments: SegmentDto[], minimum?: Date, maximum?: Date) => {
    const data = createChartData(segments);

    const axisX = (minimum && maximum)
        ? {
            labelFormatter: xLabelFormatter,
            labelAngle: -45,
            includeZero: false,
            minimum: minimum,
            maximum: maximum
        } : {
            labelFormatter: xLabelFormatter,
            labelAngle: -45,
            includeZero: false,
        };

    let options = {
        animationEnabled: true,
        exportEnabled: true,
        zoomType: "x",
        zoomEnabled: true,
        height: 230,
        axisY: {
            labelFormatter: (e: any) => {
                if (e.value === 1) {
                    return 'online';
                }
                if (e.value === 0) {
                    return 'offline';
                }
                return '';
            },
            minimum: 0,
            maximum: 1.1,
            interval: 1,
            includeZero: false,
        },
        axisX: axisX,
        title: {
            text: 'АКТИВНОСТЬ',
            fontSize: 20
        },
        data: data
    }
    developmentLog(`chart options: ${JSON.stringify(options)}`);

    return options;
}

export const ChartView: React.FC<ChartViewProps> = (props) => {
    const [chartOptions, setChartOptions] = useState<any>();
    useEffect(() => {
        if (props.segments) {
            setChartOptions(createChartOptions(props.segments, props.minimum, props.maximum));
        }
    }, [props]);

    return (
        <>
            <CanvasJSChart options={chartOptions}/>
        </>
    )
}
