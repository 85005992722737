import React from "react";
import styles from "./Refresher.module.css";

import refreshIcon from "../../infrastructure/res/commonIcons/refreshIcon.svg";

const Refresher: React.FC<{onRefresh: () => void}> = (props) => {
    return <img className={styles.refresher} src={refreshIcon} alt="refresher" onClick={props.onRefresh}/>
}

export default React.memo(Refresher);
