import React, {useState} from "react";
import {checkAuth} from "../../infrastructure/utils/AuthStorageUtils";
import {Redirect} from "react-router-dom";

import styles from "./AuthPage.module.css";
import Description from "../../components/Description/Description";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ApiRequestStatus} from "../../redux/types";
import Loader from "../../components/Loader/Loader";
import {tryLogin} from "../../redux/auth/actions";
import CommonHeading from "../../components/CommonHeading/CommonHeading";
import Button from "../../components/Button/Button";

const AuthPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const loginStatus = useAppSelector(state => state.authReducer.authData.apiRequestCondition.status);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');


    const renderAuthResponse = () => {
        if (loginStatus === ApiRequestStatus.PENDING)
            return <Loader size="small" />

        if (loginStatus === ApiRequestStatus.ERROR)
            return <Description type="warning" message="Неверные данные авторизации" />;

        return null;
    }

    const onFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        dispatch(tryLogin(login, password));
    }

    return (!checkAuth()) ? (
        <div className={styles.page}>
            <CommonHeading redirectPath="/home" cantLogout={true}/>
            <Description type="info" message="ВВЕДИТЕ ДАННЫЕ ДЛЯ ВХОДА"/>
            <form className={styles.authForm} onSubmit={onFormSubmit}>
                <input className={styles.accountInfoInput} placeholder="Login"
                       required={true}
                       maxLength={30}
                       value={login} onChange={event => setLogin(event.target.value)}
                       autoComplete="current-username"
                />

                <input className={styles.accountInfoInput} placeholder="Password"
                       required={true}
                       maxLength={30}
                       value={password} onChange={event => setPassword(event.target.value)}
                       type="password"
                       autoComplete="current-password"
                />

                <Button type="submit" additionalClassName={styles.loginButton}>ВОЙТИ</Button>
            </form>
            {renderAuthResponse()}
        </div>

    ) : <Redirect to="/targets"/>
}

export default React.memo(AuthPage);
