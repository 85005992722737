import {createReducer} from "@reduxjs/toolkit";
import {hideError, setError} from "./actions";
import {AppErrorDescriptor} from "../../infrastructure/commonTypes";


const initState: {errorOccurred?: AppErrorDescriptor | null} = {
    errorOccurred: null
};

export const errorReducer = createReducer(initState, (builder) => {
    builder
        .addCase(setError, (state, action) => {
            state.errorOccurred = action.payload;
        })
        .addCase(hideError, (state, _) => {
            console.log('here');
            // state.errorOccurred = null;
            state.errorOccurred = null;
        })
        .addDefaultCase(() => {});
});
