import {ApiRequestStatus, ApiSynchronizedData, ChartTargetInfo} from "../types";
import {createReducer} from "@reduxjs/toolkit";
import {ChartIntervalsDto} from "../../infrastructure/api/dto/ChartIntervalsDto";
import {chartLoadingError, chartLoadingStart, chartLoadingSuccess} from "./actions";

type ChartInfoWithResult = {
    chartTargetInfo?: ChartTargetInfo,
    chartData: ApiSynchronizedData<ChartIntervalsDto | undefined>
}

const initState: ChartInfoWithResult = {
    chartData: {
        data: undefined,
        apiRequestCondition: {
            status: ApiRequestStatus.SUCCESS
        }
    }
};

export const chartsReducer = createReducer(initState, (builder) => {
    builder
        .addCase(chartLoadingStart, (state, action) => {
            if (state.chartData.abortController) {
                state.chartData.abortController.abort();
            }

            state.chartTargetInfo = action.payload.chartTargetInfo;
            state.chartData.apiRequestCondition = {
                status: ApiRequestStatus.PENDING
            }
        })
        .addCase(chartLoadingSuccess, (state, action) => {
            state.chartTargetInfo = action.payload.chartTargetInfo;

            state.chartData = {
                data: action.payload.result,
                apiRequestCondition: {
                    status: ApiRequestStatus.SUCCESS
                },
                abortController: undefined
            };
        })
        .addCase(chartLoadingError, (state, action) => {
            state.chartData.abortController = undefined;
            state.chartData.data = undefined;

            state.chartData.apiRequestCondition = {
                status: ApiRequestStatus.ERROR,
                errorInfo: action.payload
            }
        });
});

