import React, {useEffect} from "react";

import styles from "./TargetsPage.module.css"
import BackWithLogo from "../../components/CommonHeading/CommonHeading";
import Description from "../../components/Description/Description";

import {TargetsContainer} from "../../components/TargetsContainer/TargetsContainer";
import Loader from "../../components/Loader/Loader";
import Refresher from "../../components/Refresher/Refresher";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {deleteTarget, reloadTargets} from "../../redux/targets/actions";
import {ApiRequestStatus} from "../../redux/types";
import ErrorPage from "../ErrorPage/ErrorPage";

export const TargetsPage: React.FC = () => {
    const {data, apiRequestCondition} = useAppSelector(state => state.targetsReducer.targetsData);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(reloadTargets());
    }, [dispatch]);

    const renderTargetsConditionally = () => {
        if (apiRequestCondition.status === ApiRequestStatus.PENDING) {
            return (
                <div className={styles.targetPage}>
                    <BackWithLogo redirectPath="/home"/>
                    <Description message="ВЫБЕРИТЕ ЦЕЛЬ ИЛИ ОТРЕДАКТИРУЙТЕ"/>
                    <Loader/>
                </div>)
        }
        if (apiRequestCondition.status === ApiRequestStatus.ERROR) {
            return <ErrorPage errorCode={apiRequestCondition.errorInfo?.message}
                              message={apiRequestCondition.errorInfo?.message}/>
        }
        return (
            <div className={styles.targetPage}>
                <BackWithLogo redirectPath="/home"/>
                <Description message="ВЫБЕРИТЕ ЦЕЛЬ ИЛИ ОТРЕДАКТИРУЙТЕ"/>
                <Refresher onRefresh={() => {
                    dispatch(reloadTargets())
                }}/>
                <TargetsContainer targetInfosWithId={data} onDelete={(id: number) => {
                    dispatch(deleteTarget(id))
                }}/>
            </div>
        );
    };

    return (
        <>
            {renderTargetsConditionally()}
        </>
    );
}
