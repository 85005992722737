import fetch from "node-fetch";
import {AbortSignal} from "node-fetch/externals";
import NetworkError from "../exceptions/NetworkError";
import {getAuthInfoFromStorage} from "../../utils/AuthStorageUtils";


type BasicFetchOptions = {
    method: 'GET' | 'POST' | 'DELETE' | 'PUT',
    authInfo?: string
    body?: string
}

export const authorizedFetch = (url: string, signal: AbortSignal, opt?: BasicFetchOptions) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': opt?.authInfo || getAuthInfoFromStorage() // || (createAuthInfo(apiConfig.AUTH_LOGIN, apiConfig.AUTH_PASSWORD))
    };

    // const headers = new Headers();
    // headers.set('Content-Type', 'application/json');
    // headers.set('Authorization', 'Basic ' + Base64.encode(apiConfig.AUTH_LOGIN + ":" + apiConfig.AUTH_PASSWORD));
    // console.log(`${opt?.method || "GET"} url: ${url}`);
    // console.log('headers: ' + JSON.stringify(headers));

    return fetch(url, {
        method: opt?.method || "GET",
        body: opt?.body,
        headers: headers,
        signal: signal
    }).catch((err) => {
        if (err.message === 'Failed to fetch') {
            throw new NetworkError(err.toString());
        }
        throw err;
    });
}
