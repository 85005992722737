import {createAction} from '@reduxjs/toolkit'
import {AppErrorDescriptor} from "../../infrastructure/commonTypes";

export enum ErrorActionTypes {
    SET_ERROR = 'SET_ERROR',
    HIDE_ERROR = 'HIDE_ERROR'
}


export const setError = createAction(
    ErrorActionTypes.SET_ERROR,
    (errorDescriptor: AppErrorDescriptor) => ({
        payload: errorDescriptor
    }));

export const hideError = createAction(ErrorActionTypes.HIDE_ERROR);
