import React, {useMemo} from "react";
import {useHistory} from "react-router-dom";
import {TargetInfoWithId} from "../../infrastructure/commonTypes";

import styles from './TargetPreview.module.css'
import {accountTypesDescription} from "../../infrastructure/accountTypesDescription";
import cn from "classnames";

type TargetPreviewProps = TargetInfoWithId;

const TargetPreview: React.FC<TargetPreviewProps> = (props) => {
    const history = useHistory();

    const iconsForSetAccounts = useMemo(() =>
            accountTypesDescription
                .filter(atd => props.targetInfo.accountsInfo.map(ai => ai.accountName).includes(atd.name))
                .map((atd, index) => <img key={index} className={styles.icon} src={atd.previewLogo}
                                          alt="accountTypeLogo"/>),
        [props.targetInfo.accountsInfo]);

    const renderPreviewLogoContainer = useMemo(() => (
        <div className={styles.smallLogos}>
            {iconsForSetAccounts}
        </div>
    ), [iconsForSetAccounts]);

    return (
        <div className={cn(styles.targetPreview, styles.clickable)}
             onClick={() => history.push(`/targetPlots/${props.id}`)}>
            <div className={styles.nicknameBlock}>
                {props.targetInfo.mainNickname}
            </div>
            {renderPreviewLogoContainer}
        </div>
    );
};

export default React.memo(TargetPreview);
