import React from "react";

import TargetPreviewEditable from "../TargetPreview/TargetPreviewEditable";
import cn from "classnames";
import addIcon from "../../infrastructure/res/commonIcons/addIcon.svg";
import styles from "./TargetsContainer.module.css";

import {useHistory} from "react-router-dom";
import {TargetInfoWithId} from "../../infrastructure/commonTypes";
import {constants} from "../../infrastructure/constants";

type TargetsContainerProps = {
    targetInfosWithId: TargetInfoWithId[],
    onDelete: (id: number) => void
}


export const TargetsContainer: React.FC<TargetsContainerProps> = (props) => {
    const history = useHistory();


    return (
        <div className={styles.targetsContainerWithAdd}>
            <div className={styles.targetsContainer}>
                {props.targetInfosWithId
                    .map((infoWithId, ind) => <TargetPreviewEditable key={ind}
                                                                     deleteCallback={() => props.onDelete(infoWithId.id as number)}
                                                                     infoWithId={infoWithId}/>)}
            </div>

            <img className={cn(styles.addIcon, styles.clickable)} src={addIcon} alt={addIcon}
                 onClick={() => history.push(constants.routing.TARGET_FORM_CREATE_ROUTE)}/>
        </div>
    );
}
