import {AccountType} from "../../commonTypes";
import {ChartTargetInfo} from "../../../redux/types";
import {developmentLog} from "../../utils/developmentLog";

import moment from 'moment';
import {toServerISODate} from "../../utils/DateUtils";

export type OnlineQueryDto = {
    accountType: AccountType,
    from: string,
    to: string,
    targetId: number
}

export const getOnlineQueryDto = (info: ChartTargetInfo, dateRange: [moment.Moment, moment.Moment]) => {
    let result: OnlineQueryDto = {
        accountType: info.accountType,
        from: moment(toServerISODate(dateRange[0].toDate())).toISOString(),
        to: moment(toServerISODate(dateRange[1].toDate())).toISOString(),
        targetId: info.id
    }
    developmentLog(`OnlineQueryDto ${JSON.stringify(result)}`);
    return result;
}
