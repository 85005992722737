import React from "react";
import {TargetInfoWithId} from "../../infrastructure/commonTypes";
import {useHistory} from "react-router-dom";

import styles from "./TargetPreview.module.css";
import cn from "classnames";
import deleteIcon from "../../infrastructure/res/commonIcons/deleteIcon.svg";
import editIcon from "../../infrastructure/res/commonIcons/editIcon.svg";

import TargetPreview from "./TargetPreview";
import {constants} from "../../infrastructure/constants";

type TargetPreviewEditableProps = {
    deleteCallback: () => void,
    infoWithId: TargetInfoWithId;
};

const TargetPreviewEditable: React.FC<TargetPreviewEditableProps> = (props) => {
    const history = useHistory();

    return (
        <div className={styles.targetPreviewWithEdit}>
            <TargetPreview {...props.infoWithId}/>
            <img className={cn(styles.clickable, styles.controlIcon)} src={deleteIcon} alt="deleteIcon"
                 onClick={props.deleteCallback}/>
            <img className={cn(styles.clickable, styles.controlIcon)} src={editIcon} alt="editIcon"
                 onClick={() => history.push(`${constants.routing.TARGET_FORM_UPDATE_ROUTE}/${props.infoWithId.id}`)}/>
        </div>
    );
}

export default React.memo(TargetPreviewEditable);
