import {authorizedFetch} from "../utils/authorizedFetch";
import {apiConfig} from "../apiConfig";
import {responseToJson} from "../utils/responseToJson";
import {ApiErrorReason} from "../ApiErrorDescriptor";
import {OnlineQueryDto} from "../dto/OnlineQueryDto";
import {AbortSignal} from "node-fetch/externals";

const CHARTS_REASON: ApiErrorReason = 'charts';

export const getPlotIntervals = (dto: OnlineQueryDto, signal: AbortSignal) =>
    authorizedFetch(`${apiConfig.BASE_URL + apiConfig.CHARTS_ROUTE}`, signal,
        {
            method: 'POST',
            body: JSON.stringify(dto)
        })
        .then(response => responseToJson(response, CHARTS_REASON));
        // .then(data => data as ChartIntervalsDto);
