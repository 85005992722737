import React from "react";

import styles from "./AccountsInfoForm.module.css"
import cn from "classnames";

export type IconParams = {
    src: string,
    alt: string,
    onClick: () => void
}

type IconsRowProps = {
    iconsParams: IconParams[],
}

const IconsRow: React.FC<IconsRowProps> = (props) => {
    const icons = props.iconsParams
        .map((iconParams, ind) => (
            <img key={ind} className={cn(styles.icon, styles.clickable)}
                 src={iconParams.src}
                 alt={iconParams.alt}
                 onClick={() => iconParams.onClick()}
            />
        ));

    return (
        <div className={styles.iconsContainer}>
            {icons}
        </div>
    );
}

export default React.memo(IconsRow);
