import {TargetInfoWithId} from "../../commonTypes";
import {AVAILABLE_ACCOUNT_TYPES} from "../../accountTypesDescription";

type TargetInfoDto = {
    id: number,
    nickname: string,
    accounts: {
        vk?: string,
        tg?: string,
        wa?: string
    }
}

export type TargetsDto = TargetInfoDto[];

export const getTargetsFromDto = (dto: TargetsDto) => {
    return dto.map(convertInfo);
}

const convertInfo = (info: TargetInfoDto) => {
    let targetInfoWithId: TargetInfoWithId = {
        id: info.id,
        targetInfo: {
            mainNickname: info.nickname,
            accountsInfo: []
        }
    }
    AVAILABLE_ACCOUNT_TYPES.forEach(name => {
        if (info.accounts[name]) {
            targetInfoWithId.targetInfo.accountsInfo.push({accountName: name, accountNickname: info.accounts[name] as string});
        }
    });

    return targetInfoWithId;
}
