import React from "react";
import {BounceLoader} from "react-spinners";

import styles from "./Loader.module.css";
import cn from "classnames";

type LoaderProps = {
    size?: 'big' | 'small';
}

const Loader: React.FC<LoaderProps> = (props) => {
    const isSmall = () => {
        return props.size === 'small';
    }
    return (
        <div className={cn(styles.loader, isSmall() ? styles.small : styles.big)}>
            <BounceLoader size={isSmall() ? "30px" : "60px"} color='black'/>
        </div>
    );
}

export default React.memo(Loader);
