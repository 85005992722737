import React, {useEffect} from 'react';
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {developmentLog} from "../infrastructure/utils/developmentLog";
import {authLogout} from "../redux/auth/actions";

// @ts-ignore
export const ErrorHandler = ({children}) => {
    const errorOccurred = useAppSelector((state) => state.errorReducer.errorOccurred);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (errorOccurred) {
            developmentLog(`error handler did catch. error message: ${errorOccurred.message}`);
            if (errorOccurred.code === '401') {
                developmentLog(`auth reset`);
                dispatch(authLogout);
            }
        }
    }, [errorOccurred, dispatch])

    return errorOccurred ?
        <ErrorPage
            errorCode={errorOccurred.code}
            message={errorOccurred.message} /> :
        children;
};

export default ErrorHandler;
