import {configureStore} from "@reduxjs/toolkit";
import {errorReducer} from "../error/reducer";
import {targetsReducer} from "../targets/reducer";
import thunk from "redux-thunk";
import logger from "redux-logger";
import {chartsReducer} from "../charts/reducer";
import {authReducer} from "../auth/reducer";

export const store = configureStore({
    reducer: {errorReducer, targetsReducer, chartsReducer, authReducer},
    middleware: process.env.REACT_APP_MODE === `development` ? [thunk, logger] : [thunk]
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
