import {Result} from "antd";
import React, {useMemo} from "react";
import {httpErrorMessages} from "../../infrastructure/httpErrorMessages";
import {useHistory} from "react-router-dom";
import {FrownFilled} from '@ant-design/icons';
import LargeButton from "../../components/Button/LargeButton";
import {useAppDispatch} from "../../redux/hooks";
import {hideError} from "../../redux/error/actions";

const ErrorPage: React.FC<{ errorCode?: string, message?: string }> = (props) => {
    const history = useHistory();

    const subtitle: string = useMemo(() => {
        if (props.message) {
            return props.message;
        }
        if (props.errorCode && httpErrorMessages.has(props.errorCode)) {
            return httpErrorMessages.get(props.errorCode) as string;
        }
        return 'Неизвестная внутренняя ошибка';
    }, [props]);

    const dispatch = useAppDispatch();



    return (
        <Result
            icon={<FrownFilled style={{color: "black"}}/>}
            title={props.errorCode || "ЧТО-ТО ПОШЛО НЕ ТАК"}
            subTitle={subtitle}
            extra={<LargeButton onClick={() => {
                dispatch(hideError());
                history.push("/");
            }}>НА ГЛАВНУЮ</LargeButton>}
        />
    );
}

export default React.memo(ErrorPage);
