import {AccountType, TargetInfoWithId} from "../../commonTypes";

type TargetToUpdateInfoDto = {
    nickname: string,
    accountsInfo: {
        vk?: string,
        tg?: string,
        wa?: string
    }
}

export type TargetToUpdateDto = {
    id?: number,
    targetsInfo: TargetToUpdateInfoDto
}

export const getTargetToUpdateDto = (target: TargetInfoWithId) => {
    let dto: TargetToUpdateDto = {
        id: target.id,
        targetsInfo: {
            nickname: target.targetInfo.mainNickname,
            accountsInfo: {}
        }
    };
    ['vk', 'tg', 'wa'].forEach(accountType => {
        let nicknames = target.targetInfo.accountsInfo
            .filter(ai => ai.accountName === accountType)
            .map(ai => ai.accountNickname);
        if (nicknames.length > 0) {
            dto.targetsInfo.accountsInfo[accountType as AccountType] = nicknames[0];
        }
    });

    return dto;
};
