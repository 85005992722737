import {setError} from "../error/actions";
import {targetsLoadingError} from "../targets/actions";
import {developmentLog} from "../../infrastructure/utils/developmentLog";
import {authError} from "../auth/actions";
import {chartLoadingError} from "../charts/actions";

export const onError = (err: any, dispatch: any) => {
    if (err.name === 'NetworkError') {
        developmentLog(`network error caught with message ${err.message}`);
        dispatch(setError({
            errorType: 'NETWORK',
            message: `Network Error`
        }));
    } else if (err.name === 'AbortError') {
        developmentLog(`The request has been aborted`);
    } else if (err.name === 'ApiError') {
        switch (err.descriptor.reason) {
            case "charts":
                dispatch(chartLoadingError(err.descriptor));
                break;
            case "targets":
                dispatch(targetsLoadingError(err.descriptor));
                break;
            case "auth":
                dispatch(authError(err.descriptor));
                break;
            case "common":
                dispatch(setError({
                    errorType: 'API',
                    message: `Api error occurred. ${err.message}`
                }));
        }
    } else {
        dispatch(setError({
            errorType: 'INTERNAL',
            message: `Internal error occurred. ${err.toString()}`
        }));
    }
    return err;
}
