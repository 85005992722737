
import React from "react";
import {useHistory} from "react-router-dom";

import styles from "./LandingPage.module.css"
import LargeButton from "../../components/Button/LargeButton";
import MainLogo from "../../components/MainLogo/MainLogo";


const LandingPage: React.FC = () => {
    const history = useHistory();
    return (
        <div className={styles.page}>
            <div><MainLogo /></div>
            <LargeButton additionalClassName={styles.startButton} onClick={() => history.push("/targets")}>НАЧАТЬ</LargeButton>
        </div>

    );
}

export default React.memo(LandingPage);
