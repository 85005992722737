import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import Description from "../Description/Description";
import BackWithLogo from "../CommonHeading/CommonHeading";
import {accountTypesDescription} from "../../infrastructure/accountTypesDescription";

import styles from './AccountsInfoForm.module.css';
import LargeButton from "../Button/LargeButton";
import {AccountInfo, AppErrorDescriptor, TargetInfo} from "../../infrastructure/commonTypes";
import IconsRow from "./IconsRow";
import AccountTypeInfoInput from "./AccountTypeInfoInput";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ApiRequestStatus} from "../../redux/types";
import {setError} from "../../redux/error/actions";
import {updateTarget} from "../../redux/targets/actions";
import Loader from "../Loader/Loader";
import {developmentLog} from "../../infrastructure/utils/developmentLog";
import {preventEnterSubmission} from "../../infrastructure/utils/FormUtils";

type AccountsInfoFormProps = {
    id?: number,
    targetInfo?: TargetInfo
};


const defaultState = {mainNickname: '', accountsInfo: []};


export const AccountsInfoForm: React.FC<AccountsInfoFormProps> = (props) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const targetsLoadingCondition = useAppSelector(appState => appState.targetsReducer.targetsData.apiRequestCondition);

    const [state, setState] = useState(props.targetInfo || defaultState);
    const [mayRedirect, setMayRedirect] = useState<boolean>(false);
    const [badRequestMsg, setBadRequestMsg] = useState<string | undefined>(undefined);

    useEffect(() => {
        setState(props.targetInfo || defaultState);
    }, [props.targetInfo]);

    useEffect(() => {
        if (mayRedirect && targetsLoadingCondition.status === ApiRequestStatus.SUCCESS) {
            history.push('/targets');
            return;
        }

        if (mayRedirect && targetsLoadingCondition.status === ApiRequestStatus.ERROR) {
            if (targetsLoadingCondition.errorInfo?.code === 400) {
                setBadRequestMsg(`Некорректные данные: ${targetsLoadingCondition.errorInfo?.message}`);
            } else {
                dispatch(setError({
                    errorType: 'API',
                    code: targetsLoadingCondition.errorInfo?.code,
                    message: targetsLoadingCondition.errorInfo?.message
                } as AppErrorDescriptor));
            }
        }
    }, [targetsLoadingCondition, mayRedirect, dispatch, history]);

    //region changing state
    const changeMainNickname = (newNickName: string) => {
        let newState = {...state};
        newState.mainNickname = newNickName;
        setState(newState);
    };

    const changeAccountsInfo = (newAccountsInfo: AccountInfo[]) => {
        let newState = {...state};
        newState.accountsInfo = newAccountsInfo;
        setState(newState);
    }

    const addEmptyAccountInfo = (accountName: string) => addAccountInfo({
        accountName: accountName,
        accountNickname: ''
    });

    const addAccountInfo = (accountInfo: AccountInfo) => {
        let newAccountsInfo = [...(state.accountsInfo.filter(ai => ai.accountName !== accountInfo.accountName)), accountInfo];
        changeAccountsInfo(newAccountsInfo);
    }

    const updateAccountInfo = (accountInfo: AccountInfo) => {
        let newAccountsInfo = state.accountsInfo.map(ai =>
            (ai.accountName === accountInfo.accountName) ? accountInfo : {...ai}
        );
        changeAccountsInfo(newAccountsInfo);
    };

    const deleteAccountInfo = (accountInfo: AccountInfo) => {
        let newAccountInfo = state.accountsInfo.filter(ai => ai.accountName !== accountInfo.accountName);
        changeAccountsInfo(newAccountInfo);
    }
    //endregion

    //region render helpers
    const getUnusedIconsParams = () => {
        return accountTypesDescription
            .filter(atd => !state.accountsInfo.map(ai => ai.accountName).includes(atd.name))
            .map((atd) => ({
                src: atd.logo,
                alt: `${atd.name} logo`,
                onClick: () => addEmptyAccountInfo(atd.name)
            }));
    };

    const createAccountTypeInfoInput = (accountInfo: AccountInfo, ind: number) => (
        <AccountTypeInfoInput
            key={ind}
            value={accountInfo.accountNickname}
            code={accountInfo.accountName}
            onChange={(newValue) => updateAccountInfo({
                accountName: accountInfo.accountName,
                accountNickname: newValue
            })}
            onDelete={() => deleteAccountInfo(accountInfo)}
        />
    );

    const renderForm = () => (
        <>
            <input className={styles.mainNickNameInput} placeholder="NICKNAME"
                   required={true}
                   pattern="[А-яА-ЯA-Za-z0-9]+" maxLength={50}
                   title="Строка из букв и цифр"
                   value={state.mainNickname}
                   onChange={(event) => changeMainNickname(event.target.value)}/>
            <IconsRow iconsParams={getUnusedIconsParams()}/>

            <div className={styles.accountsInfoWithButton}>
                <div className={styles.accountsInfoContainer}>
                    {state.accountsInfo.map(createAccountTypeInfoInput)}
                </div>

                {state.accountsInfo.length > 0 ?
                    <LargeButton type="submit">СОХРАНИТЬ</LargeButton>
                    : null}
            </div>

            {badRequestMsg ? <Description type="warning" message={badRequestMsg}/> : null}
        </>
    );
    //endregion

    //region submission
    const submitForm = (event: React.FormEvent) => {
        event.preventDefault();

        developmentLog('submission: ' + JSON.stringify(state));
        dispatch(updateTarget({id: props.id, targetInfo: {...state}}));
        setMayRedirect(true);
        setBadRequestMsg(undefined);
    }


    //endRegion
    return (
        <form onSubmit={(event => submitForm(event))} onKeyPress={preventEnterSubmission} className={styles.accountsInfoForm}>
            <BackWithLogo redirectPath="/targets"/>
            <Description message='ВВЕДИТЕ NICKNAME И ССЫЛКИ НА СОЦИАЛЬНЫЕ СЕТИ'/>
            {targetsLoadingCondition.status === ApiRequestStatus.PENDING ? <Loader/> : renderForm()}
        </form>
    );
}
